.title,
.contact {
  font-family: monospace;
  display: flex;
  position: relative;
  margin: 0 auto;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  max-width: 800px;
  width: 100%;
  font-weight: bold;
  padding: 65px 5px 5px 5px;
}

.contact {
  display: grid;
}

.experience {
  font-family: monospace;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  font-weight: bold;
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  padding: 65px 5px 5px 5px;
}

.tech-stack {
  font-family: monospace;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  font-weight: bold;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  padding: 65px 5px 5px 5px;
}

.profileImage {
  border-radius: 100%;
  height: 12rem;
}

.image {
  text-align: center;
  border-radius: 100%;
  margin-right: 20px;
}

.work-exp + .work-exp {
  margin-top: 30px;
}

h4 {
  font-weight: 800;
}

.work-experience-list {
  list-style: none;
  padding: 0%;
}

.work-experience-list li:before {
  content: "✓ ";
  font-size: 1.1rem;
}

.work-experience-list > li {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.1rem;
}

.logo {
  height: 4rem;
  transition: all 0.5s;
}

.logo:hover {
  height: 7rem;
}

.card {
  background-color: rgb(245, 243, 243);
  border: none;
}

/* At the top level of your code */
@media screen and (max-width: 900px) {
  .title {
    display: block;
  }
  .image {
    padding: 3rem 0;
  }
}
